import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import organisationReducer from '../organisationSlice';
import userReducer from '../userSlice';
var store = configureStore({
    reducer: {
        organisation: organisationReducer,
        user: userReducer,
    },
});
export default store;
export var useJotpaRekisterointiDispatch = useDispatch;
export var useJotpaRekisterointiSelector = useSelector;
