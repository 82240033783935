import * as yup from 'yup';
export var KoodiSchema = function (koodit) {
    return yup
        .object()
        .shape({
        label: yup.string().required('validaatio_pakollinen'),
        value: yup
            .string()
            .required('validaatio_pakollinen')
            .oneOf(koodit.map(function (k) { return k.uri; }), 'validaatio_geneerinen'),
    })
        .required('validaatio_pakollinen');
};
export var PuhelinnumeroSchema = yup
    .string()
    .required('validaatio_pakollinen')
    .matches(/^(\+|-| |\(|\)|[0-9]){3,100}$/, 'validaatio_geneerinen');
export var PostinumeroSchema = function (postinumerot) { return yup.string().oneOf(postinumerot, 'validaatio_postinumero'); };
export var EmailSchema = yup.string().email('validaatio_email').required('validaatio_pakollinen');
export var EmailArraySchema = yup
    .array()
    .required()
    .min(1, 'validaatio_email')
    .of(yup.object().shape({
    email: yup.string().email('validaatio_email').required('validaatio_pakollinen'),
}));
export var PostiosoiteSchema = yup.string();
export var DateStringSchema = yup
    .string()
    .nullable()
    .required('validaatio_pakollinen')
    .matches(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}/, 'validaatio_geneerinen');
