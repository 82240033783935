var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormError } from './FormError';
import styles from './Input.module.css';
export var Input = function (_a) {
    var name = _a.name, required = _a.required, register = _a.register, error = _a.error;
    var isRequired = required !== null && required !== void 0 ? required : true;
    return (_jsxs("div", { children: [_jsx("input", __assign({ id: name, className: "".concat(styles.input, " ").concat(error ? styles.error : ''), type: "text", autoComplete: "off", "aria-invalid": !!error, "aria-errormessage": "#error-".concat(name), "aria-required": isRequired }, register(name, { required: isRequired }))), _jsx(FormError, { id: "error-".concat(name), error: error === null || error === void 0 ? void 0 : error.message, inputId: name })] }));
};
