var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import { Header } from './JotpaHeader';
import { useLanguageContext } from '../LanguageContext';
import styles from './JotpaLanding.module.css';
import { JotpaProsessikuvaus } from './JotpaProsessikuvaus';
var ExternalLink = function () { return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z", fill: "#3A7A10" }) }))); };
export function JotpaLanding() {
    var i18n = useLanguageContext().i18n;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: i18n.translate('title') }) }), _jsx(Header, { title: i18n.translate('otsikko_rekisterointi') }), _jsxs("main", __assign({ id: "main" }, { children: [_jsx("div", __assign({ className: styles.bannerContainer }, { children: _jsx("img", { className: styles.banner, src: "/jotpa_banner.png", alt: "" }) })), _jsxs("div", __assign({ className: "content" }, { children: [_jsx(Markdown, { children: i18n.translate('etusivu_mika_jotpa') }), _jsx("h3", { children: i18n.translate('etusivu_prosessikuvaus') }), _jsx(JotpaProsessikuvaus, {}), _jsx(Markdown, { children: i18n.translate('etusivu_rekisterointi') }), _jsxs("p", __assign({ className: styles.link }, { children: [_jsx("a", __assign({ href: i18n.translate('linkki_palvelun_kayttoohje_osoite'), target: "_blank", rel: "noopener noreferrer" }, { children: i18n.translate('linkki_palvelun_kayttoohje') })), _jsx(ExternalLink, {})] })), _jsxs("p", __assign({ className: styles.link }, { children: [_jsx("a", __assign({ href: i18n.translate('linkki_jotpa_esittely_osoite'), target: "_blank", rel: "noopener noreferrer" }, { children: i18n.translate('linkki_jotpa_esittely') })), _jsx(ExternalLink, {})] })), _jsx("p", __assign({ className: styles.link }, { children: _jsx("a", __assign({ href: i18n.translate('linkki_saavutettavuusseloste_osoite'), target: "_blank", rel: "noopener noreferrer" }, { children: i18n.translate('linkki_saavutettavuusseloste') })) })), _jsx("button", __assign({ role: "link", className: styles.registerButton, onClick: function () { return (window.location.href = '/hakija/jotpa/organisaatio?login=true'); } }, { children: i18n.translate('etusivu_aloitus_nappi') }))] }))] }))] }));
}
