var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { setLocale } from 'yup';
import { Helmet } from 'react-helmet';
import store from './store';
import { JotpaOrganisaatio } from './JotpaOrganisaatio';
import { fetchOrganisation, OrganisationSchema } from '../organisationSlice';
import { KoodistoContext } from '../KoodistoContext';
import { JotpaPaakayttaja } from './JotpaPaakayttaja';
import { JotpaWizardValidator } from './JotpaWizardValidator';
import { useLanguageContext } from '../LanguageContext';
import { UserSchema } from '../userSlice';
import { JotpaYhteenveto } from './JotpaYhteenveto';
setLocale({
    mixed: {
        required: 'validaatio_pakollinen',
        notType: 'validaatio_geneerinen',
    },
    string: {
        matches: 'validaatio_geneerinen',
        email: 'validaatio_email',
    },
});
var koodistoNimiComparator = function (language) { return function (a, b) { var _a, _b; return ((_a = a.nimi[language]) !== null && _a !== void 0 ? _a : 'xxx') > ((_b = b.nimi[language]) !== null && _b !== void 0 ? _b : 'xxx') ? 1 : -1; }; };
export function JotpaRekisterointi() {
    store.dispatch(fetchOrganisation());
    var _a = useLanguageContext(), language = _a.language, i18n = _a.i18n;
    var _b = useState({
        kunnat: [],
        yritysmuodot: [],
        organisaatiotyypit: [],
        posti: [],
        postinumerot: [],
    }), koodisto = _b[0], setKoodisto = _b[1];
    useEffect(function () {
        function fetchKoodisto() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, kunnat, yritysmuodot, organisaatiotyypit, posti, postinumerot;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Promise.all([
                                axios.get('/api/koodisto/KUNTA/koodi?onlyValid=true'),
                                axios.get('/api/koodisto/YRITYSMUOTO/koodi?onlyValid=true'),
                                axios.get('/api/koodisto/ORGANISAATIOTYYPPI/koodi?onlyValid=true'),
                                axios.get('/api/koodisto/POSTI/koodi?onlyValid=true'),
                            ])];
                        case 1:
                            _a = _b.sent(), kunnat = _a[0].data, yritysmuodot = _a[1].data, organisaatiotyypit = _a[2].data, posti = _a[3].data;
                            kunnat.sort(koodistoNimiComparator(language));
                            yritysmuodot.sort(koodistoNimiComparator(language));
                            organisaatiotyypit.sort(koodistoNimiComparator(language));
                            postinumerot = posti.map(function (p) { return p.arvo; });
                            setKoodisto({
                                kunnat: kunnat,
                                yritysmuodot: yritysmuodot,
                                organisaatiotyypit: organisaatiotyypit,
                                posti: posti,
                                postinumerot: postinumerot,
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        void fetchKoodisto();
    }, []);
    var organisationValidation = {
        slice: 'organisation',
        schema: OrganisationSchema(koodisto.yritysmuodot, koodisto.kunnat, koodisto.postinumerot),
        redirectPath: '/hakija/jotpa/organisaatio',
    };
    var userValidation = {
        slice: 'user',
        schema: UserSchema,
        redirectPath: '/hakija/jotpa/paakayttaja',
    };
    return (_jsxs(KoodistoContext.Provider, __assign({ value: koodisto }, { children: [_jsx(Helmet, { children: _jsx("title", { children: i18n.translate('title') }) }), _jsx(Provider, __assign({ store: store }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/organisaatio", element: _jsx(JotpaOrganisaatio, {}) }), _jsx(Route, { path: "/paakayttaja", element: _jsx(JotpaWizardValidator, __assign({ validate: [organisationValidation] }, { children: _jsx(JotpaPaakayttaja, {}) })) }), _jsx(Route, { path: "/yhteenveto", element: _jsx(JotpaWizardValidator, __assign({ validate: [organisationValidation, userValidation] }, { children: _jsx(JotpaYhteenveto, {}) })) })] }) }))] })));
}
