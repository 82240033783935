import React, { useContext } from 'react';
var I18nImpl = /** @class */ (function () {
    function I18nImpl(data, language) {
        this._data = data;
        this._language = language;
    }
    I18nImpl.prototype.translate = function (key) {
        return this.translateWithLang(key, this._language);
    };
    I18nImpl.prototype.translateWithLang = function (key, language) {
        return this._data[language][key] || key;
    };
    return I18nImpl;
}());
export { I18nImpl };
export var defaultLokalisointi = { fi: {}, sv: {}, en: {} };
export var LanguageContext = React.createContext({
    language: 'fi',
    setLanguage: function (language) { },
    i18n: new I18nImpl(defaultLokalisointi, 'fi'),
});
export var useLanguageContext = function () {
    var context = useContext(LanguageContext);
    if (!context && context !== null) {
        throw new Error('Localization not available');
    }
    return context;
};
