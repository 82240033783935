var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
// import global styles first
import 'normalize.css';
import 'oph-virkailija-style-guide/oph-styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import { defaultLokalisointi, I18nImpl, LanguageContext } from './LanguageContext';
import { JotpaRekisterointi } from './jotpa/JotpaRekisterointi';
import { JotpaLanding } from './jotpa/JotpaLanding';
import { JotpaValmis } from './jotpa/JotpaValmis';
import { Footer } from './Footer';
registerLocale('fi', fi);
registerLocale('sv', sv);
function App() {
    var _a = useState('fi'), language = _a[0], setLanguage = _a[1];
    var _b = useState(), localization = _b[0], setLocalization = _b[1];
    var _c = useState(new I18nImpl(defaultLokalisointi, language)), i18n = _c[0], setI18n = _c[1];
    useEffect(function () {
        function fetchLocalization() {
            return __awaiter(this, void 0, void 0, function () {
                var _a, langResp, localizationResp, _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            _c = (_b = Promise).all;
                            return [4 /*yield*/, axios.get('/api/lokalisointi/kieli')];
                        case 1:
                            _d = [
                                _e.sent()
                            ];
                            return [4 /*yield*/, axios.get('/api/lokalisointi?category=jotpa-rekisterointi')];
                        case 2: return [4 /*yield*/, _c.apply(_b, [_d.concat([
                                    _e.sent()
                                ])])];
                        case 3:
                            _a = _e.sent(), langResp = _a[0], localizationResp = _a[1];
                            setLanguage(langResp.data);
                            setLocalization(localizationResp.data);
                            setI18n(new I18nImpl(localizationResp.data, language));
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (!localization) {
            void fetchLocalization();
        }
        else {
            setI18n(new I18nImpl(localization, language));
        }
    }, [language, localization]);
    if (!localization) {
        return _jsx("div", {});
    }
    return (_jsxs(LanguageContext.Provider, __assign({ value: { language: language, setLanguage: setLanguage, i18n: i18n } }, { children: [_jsx("a", __assign({ className: "skip-to-content", href: "#main" }, { children: i18n.translate('hyppaa_sisaltoon') })), _jsxs(Router, { children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/jotpa", element: _jsx(JotpaLanding, {}) }), _jsx(Route, { path: "/jotpa/valmis", element: _jsx(JotpaValmis, {}) }), _jsx(Route, __assign({ path: "/hakija" }, { children: _jsx(Route, { path: "jotpa/*", element: _jsx(JotpaRekisterointi, {}) }) }))] }), _jsx(Outlet, {})] }), _jsx(Footer, {})] })));
}
export default App;
