import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { FormError } from './FormError';
import { useLanguageContext } from './LanguageContext';
var dateFormat = 'd.M.yyyy';
var parseDate = function (value) {
    var date = value ? parse(value, dateFormat, new Date()) : null;
    if ((date === null || date === void 0 ? void 0 : date.toString()) === 'Invalid Date') {
        return null;
    }
    return date;
};
export var DatePicker = function (_a) {
    var name = _a.name, control = _a.control, error = _a.error;
    var language = useLanguageContext().language;
    return (_jsxs("div", { children: [_jsx(Controller, { name: name, control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(ReactDatePicker, { id: name, locale: language, "aria-invalid": !!error, "aria-errormessage": "#error-".concat(name), "aria-live": "polite", "aria-required": true, onChange: function (e) { return field.onChange(e && format(e, dateFormat)); }, selected: parseDate(field.value), dateFormat: dateFormat, className: error ? 'invalid_date' : '' }));
                } }), _jsx(FormError, { id: "error-".concat(name), error: error === null || error === void 0 ? void 0 : error.message, inputId: name })] }));
};
