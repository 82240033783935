import React, { useContext } from 'react';
var defaultKoodistos = {
    kunnat: [],
    yritysmuodot: [],
    organisaatiotyypit: [],
    posti: [],
    postinumerot: [],
};
export var KoodistoContext = React.createContext(defaultKoodistos);
export var useKoodistos = function () {
    var context = useContext(KoodistoContext);
    if (!context && context !== null) {
        throw new Error('Koodisto not available');
    }
    return context;
};
