import { createSlice } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { EmailSchema } from './yupSchemas';
var initialState = {};
var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setForm: function (state, action) {
            state.form = action.payload;
        },
    },
});
export default userSlice.reducer;
export var setForm = userSlice.actions.setForm;
export var UserSchema = yup.object().shape({
    etunimi: yup.string().required('validaatio_pakollinen'),
    sukunimi: yup.string().required('validaatio_pakollinen'),
    paakayttajaEmail: EmailSchema,
    asiointikieli: yup.mixed().oneOf(['fi', 'sv']).required('validaatio_pakollinen'),
    info: yup.string().optional(),
});
