// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V3dHMuyuT0jFj2GEbexz {\n    min-height: 55vh;\n}\n\n.cz9QiD1v0h_trPBLSVAW {\n    display: flex;\n    align-items: center;\n}\n\n.TOZbwQ0d3HCKa7OZ0LeP {\n    margin-left: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/jotpa/JotpaValmis.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".successContent {\n    min-height: 55vh;\n}\n\n.success {\n    display: flex;\n    align-items: center;\n}\n\n.successDescription {\n    margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successContent": "V3dHMuyuT0jFj2GEbexz",
	"success": "cz9QiD1v0h_trPBLSVAW",
	"successDescription": "TOZbwQ0d3HCKa7OZ0LeP"
};
export default ___CSS_LOADER_EXPORT___;
