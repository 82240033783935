var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLanguageContext } from './LanguageContext';
import styles from './FormError.module.css';
export var FormError = function (_a) {
    var id = _a.id, error = _a.error, inputId = _a.inputId;
    var i18n = useLanguageContext().i18n;
    return error ? (_jsx("div", __assign({ role: "alert", id: id, className: styles.error, "data-test-id": "error-".concat(inputId) }, { children: i18n.translate(error) }))) : null;
};
