var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import { FormError } from './FormError';
import { reactSelectStyles, DropdownIndicator } from './select-styles';
export var Select = function (_a) {
    var name = _a.name, control = _a.control, options = _a.options, ariaLabel = _a.ariaLabel, error = _a.error;
    return (_jsxs("div", { children: [_jsx(Controller, { name: name, control: control, render: function (_a) {
                    var field = _a.field;
                    return (_jsx(ReactSelect, __assign({}, field, { id: name, inputId: "select-".concat(name), placeholder: "".concat(ariaLabel, "..."), styles: reactSelectStyles(!!error), components: { DropdownIndicator: DropdownIndicator }, options: options, "aria-label": ariaLabel, "aria-invalid": !!error, "aria-errormessage": "#error-".concat(name), "aria-required": true })));
                } }), _jsx(FormError, { id: "error-".concat(name), error: error === null || error === void 0 ? void 0 : error.message, inputId: name })] }));
};
