// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agIqryvtEgr38oqVtnVR {\n    height: 40px;\n    width: 100%;\n    padding: 2px 8px;\n    border-radius: 2px;\n    border: 1px solid var(--light-gray);\n    margin: 1px;\n}\n\n.ZtJ90aGzSdHJlTnBNZJl {\n    border: 2px solid var(--red-error);\n    margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/Input.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,mCAAmC;IACnC,WAAW;AACf;;AAEA;IACI,kCAAkC;IAClC,SAAS;AACb","sourcesContent":[".input {\n    height: 40px;\n    width: 100%;\n    padding: 2px 8px;\n    border-radius: 2px;\n    border: 1px solid var(--light-gray);\n    margin: 1px;\n}\n\n.error {\n    border: 2px solid var(--red-error);\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "agIqryvtEgr38oqVtnVR",
	"error": "ZtJ90aGzSdHJlTnBNZJl"
};
export default ___CSS_LOADER_EXPORT___;
