var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguageContext } from './LanguageContext';
import styles from './RegistrationProgressBar.module.css';
var ActivePhase = function (_a) {
    var phase = _a.phase;
    return (_jsxs("svg", __assign({ width: "37", height: "35", viewBox: "0 0 37 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("ellipse", { cx: "18.7286", cy: "17.5", rx: "18.1827", ry: "17.5", fill: "#3A7A10" }), _jsx("text", __assign({ x: "50%", y: "67%", textAnchor: "middle", fill: "#FFFFFF", fontSize: "large" }, { children: phase }))] })));
};
var DonePhase = function () { return (_jsxs("svg", __assign({ width: "37", height: "35", viewBox: "0 0 37 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("ellipse", { cx: "18.2257", cy: "17.5", rx: "18.1827", ry: "17.5", fill: "#3A7A10" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.1089 21.6698L10.7762 17.4998L9.30078 18.9098L15.1089 24.4998L27.577 12.4998L26.112 11.0898L15.1089 21.6698Z", fill: "white" })] }))); };
var UpcomingPhase = function (_a) {
    var phase = _a.phase;
    return (_jsxs("svg", __assign({ width: "40", height: "37", viewBox: "0 0 40 37", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("ellipse", { cx: "19.8761", cy: "18.5", rx: "18.1827", ry: "17.5", fill: "white", stroke: "#B2B2B2", strokeWidth: "2" }), _jsx("text", __assign({ x: "50%", y: "67%", textAnchor: "middle", fill: "#4c4c4c", fontSize: "large" }, { children: phase }))] })));
};
export var RegistrationProgressBar = function (_a) {
    var currentPhase = _a.currentPhase, phaseTranslationKeys = _a.phaseTranslationKeys;
    var i18n = useLanguageContext().i18n;
    return (_jsx("div", __assign({ className: styles.progressBar, "aria-hidden": true }, { children: phaseTranslationKeys.map(function (k, idx) {
            var dottedLineClassName = idx === 0
                ? styles.leftLine
                : idx === phaseTranslationKeys.length - 1
                    ? styles.rightLine
                    : styles.fullLine;
            var LogoElement = idx === currentPhase - 1 ? (_jsx(ActivePhase, { phase: currentPhase })) : idx < currentPhase ? (_jsx(DonePhase, {})) : (_jsx(UpcomingPhase, { phase: idx + 1 }));
            return (_jsxs("div", __assign({ className: styles.phase }, { children: [_jsx("div", { className: dottedLineClassName }), _jsx("div", { children: LogoElement }), _jsx("div", __assign({ className: styles.phaseName }, { children: _jsx("span", __assign({ className: currentPhase === idx + 1 ? styles.activeName : undefined }, { children: i18n.translate(k) })) }))] }), k));
        }) })));
};
