// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CpXqO57tzLcKc98j9SAv {\n    line-height: 22px;\n    font-size: 16px;\n    color: var(--red-error);\n}\n", "",{"version":3,"sources":["webpack://./src/FormError.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".error {\n    line-height: 22px;\n    font-size: 16px;\n    color: var(--red-error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "CpXqO57tzLcKc98j9SAv"
};
export default ___CSS_LOADER_EXPORT___;
