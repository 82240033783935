export var getLanguageName = function (langTranslation, currentLanguage) {
    return {
        fi: {
            fi: 'Suomi',
            sv: 'Ruotsi',
            en: 'Englanti',
        },
        sv: {
            fi: 'Finska',
            sv: 'Svenska',
            en: 'Engelska',
        },
        en: {
            fi: 'Finnish',
            sv: 'Swedish',
            en: 'English',
        },
    }[langTranslation][currentLanguage];
};
