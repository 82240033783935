import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useJotpaRekisterointiSelector } from './store';
function getRedirectPath(state, validate) {
    for (var _i = 0, validate_1 = validate; _i < validate_1.length; _i++) {
        var _a = validate_1[_i], schema = _a.schema, slice = _a.slice, redirectPath = _a.redirectPath;
        if (!schema.isValidSync(state[slice].form)) {
            return redirectPath;
        }
    }
}
export function JotpaWizardValidator(_a) {
    var validate = _a.validate, children = _a.children;
    var state = useJotpaRekisterointiSelector(function (state) { return state; });
    var navigate = useNavigate();
    var redirect = getRedirectPath(state, validate);
    useEffect(function () {
        if (redirect) {
            navigate(redirect);
        }
    }, [navigate, redirect]);
    return _jsx(_Fragment, { children: children });
}
