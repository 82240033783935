// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LXIXW7BN273Cvsbgrmke {\n    width: 100%;\n    background-color: #3a7a10;\n}\n\n.sK1OMvUoDPfHgpHGl2qj {\n    display: block;\n    width: 100%;\n    mix-blend-mode: overlay;\n}\n\n.fbkFJsthSdPwOrHQsLzx {\n    padding: 13px 79px;\n    border: none;\n    background: var(--green);\n    color: var(--white);\n}\n\n.vsJlaexvoLJmSn9Opan6 {\n    display: flex;\n}\n\n.vsJlaexvoLJmSn9Opan6 > svg {\n    padding-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/jotpa/JotpaLanding.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".bannerContainer {\n    width: 100%;\n    background-color: #3a7a10;\n}\n\n.banner {\n    display: block;\n    width: 100%;\n    mix-blend-mode: overlay;\n}\n\n.registerButton {\n    padding: 13px 79px;\n    border: none;\n    background: var(--green);\n    color: var(--white);\n}\n\n.link {\n    display: flex;\n}\n\n.link > svg {\n    padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": "LXIXW7BN273Cvsbgrmke",
	"banner": "sK1OMvUoDPfHgpHGl2qj",
	"registerButton": "fbkFJsthSdPwOrHQsLzx",
	"link": "vsJlaexvoLJmSn9Opan6"
};
export default ___CSS_LOADER_EXPORT___;
